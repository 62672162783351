<template>
  <div :class="$style.root">
    <VDataTable
        fixed-header
        height="calc(100vh - 200px)"
        hideDefaultHeader
        :headers="headers"
        :items="listItems"
        :loading="loading"
        :footer-props="footer"
        :options.sync="options"
        :server-items-length="count"
        :mobile-breakpoint="0"
        @update:page="page => onPagination({ page })"
        @update:items-per-page="size => onPagination({ size })"
    >
      <template v-slot:header>
        <thead class="v-data-table-header">
          <tr>
            <th v-for="{text, value, colspan } in headers" :class="headerClassControl(value)" :colspan="colspan" :key="text">{{text}}</th>
          </tr>
          <FilterRow :items="headers" @input="onPagination" />
        </thead>
      </template>
      <template v-slot:item="{ headers, item }">
        <tr>
          <td v-for="({ value }) in headers" :key="value">
            <template v-if="~value.indexOf('question_')">
              <CompactCeil :text="getQuestionData(item ,value)" />
            </template>
            <template v-else>
              {{ item[value] }}
            </template>
          </td>
        </tr>
      </template>
    </VDataTable>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ROLES } from '@/store/user/enums';
import FilterRow from '@/components/pkosmr/FilterRow/FilterRow.vue';
import CompactCeil from '@/components/pkosmr/SurveyList/components/CompactCeil';
import {get} from 'lodash-es';

export default {
  name: 'SurveyList',
  components: {
    CompactCeil,
    FilterRow,
  },
  props: {
    headers: { type: Array },
    items: { type: Array },
    count: { type: Number },
    page: { type: Number },
    size: { type: Number },
    loading: { type: Boolean },
  },
  data: function() {
    return {
      footer: {
        itemsPerPageText: '',
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 10, 20, 50, 100, 500],
      },
      options: {
        page: this.page,
        itemsPerPage: this.size,
      },
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/current',
      hasRole: 'user/hasRole',
      pending: 'pkosmr/pending',
    }),
    listItems() {
      return this.items;
    },
    isReviewer() {
      return this.hasRole([ROLES.REVIEWER, ROLES.FIRST_REVIEWER, ROLES.SECOND_REVIEWER, ROLES.LAWYER, ROLES.APPROVER], 'pkosmr');
    },
  },
  methods: {
    onPagination(params) {
      const { query } = this.$route;
      this.$router.push({ query: { ...query, ...params } });
    },
    getQuestionData(item, value) {
      return get(item, value + '.value', '');
    },
    headerClassControl(column) {
      const baseClasses = ['text-start'];
      if (column === 'inn') return [...baseClasses, ...[this.$style.headerItem]];
      return baseClasses;
    }
  }
}
</script>

<style module lang="scss">
.root {
  td {
    width: 500px;
    min-width: 230px;
    &:nth-child(1) { max-width: 50px; min-width: 50px; }
    &:nth-child(2) { max-width: 100px; }
    &:nth-child(3) { max-width: 105px; }
    &:nth-child(4) { max-width: 130px; }
    &:nth-child(5) { max-width: 100px; }
    &:nth-child(6) { max-width: 200px; }
    &:nth-child(7) { max-width: 180px; }
    &:nth-child(8) { max-width: 180px; }
    &:nth-child(9) { max-width: 180px; }
    &:nth-child(10) { max-width: 180px; }
    &:nth-child(11) { max-width: 200px; }
    &:nth-child(12) { max-width: 200px; }
    &:nth-child(13) { max-width: 180px; }
    &:nth-child(14) { max-width: 300px; }
  }
}
.headerItem {
  min-width: 230px;
}
</style>
